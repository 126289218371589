<template>
   <div class="login">
      <div class="container">
        <div class="row justify-content-center align-items-center d-flex vh-100">
          <div class="col-lg-5 mx-auto">
            <div class="osahan-login p-5 bg-dark">
              <div class="text-center mb-4">
                <router-link to="/"><img src="../../assets/image/logo.png" style="height: 90px;" alt=""></router-link>
                 <h5 class="font-weight-bold mt-3" style="color: #fff;"> إلغاء الإشتراك </h5>
              </div>
              <form  @submit.prevent="unsubscribe()">
                <!-- <button class="btn btn-warning btn-lg btn-block text-uppercase" type="submit">تسجيل الدخول</button> -->
                <button class="btn btn-warning btn-lg btn-block text-uppercase" type="submit">
                <span v-if="loading" class="px-1 arabickufi">جاري الغاء الاشتراك</span> &nbsp;
                <div v-if="loading" class="spinner-border spinner-border-sm"></div>
                <span v-else class="arabickufi">الغاء الاشتراك</span>
            </button>

              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import { HTTPSPA } from '@/Api/http-Spay';
import { useToast } from "vue-toastification";
export default {
   name: 'AppUnsubscribes',
   data() {
     return {
       loading: false,
     };
   },
    mounted() {
       if (!this.$cookie.isCookieAvailable("msisdn") && !this.$cookie.isCookieAvailable("status")) {
          this.$router.push({ path: "/" })
       }
    },
   methods: {
      async unsubscribe () {
        const toast = useToast();
        //   let button = document.querySelector(".button");
        //   button.disabled = true;
        // this.loading = true;
        await HTTPSPA.post("SpayUnsubscribe.php?msisdn="+this.$cookie.getCookie("msisdn")).then((response) => {
          if (response.data.status === 0) {
            toast.error("انت لست مشترك في خدمة لعبتنا", { timeout: 2500 });
            this.$cookie.keys().forEach(cookie => this.$cookie.removeCookie(cookie))
            this.$router.push('/')
          } else {
            toast.success("تم الغاء اشتراكك من خدمة لعبتنا ", { timeout: 2500 });
            this.$cookie.keys().forEach(cookie => this.$cookie.removeCookie(cookie))
            this.$router.push('/')
          }
        }).catch((error) => {
            console.log(error);
        }).finally(() => {
            this.loading = false;
        });
      },
   }
}
</script>

<style scoped>
.btn-warning {
  color: #fff;
  border-color: rgb(131, 203, 206);
  background: rgb(4, 72, 113) none repeat scroll 0% 0% !important
}

.bg-dark {
  background: linear-gradient(0deg, #097ABD 0, #005C93);
}
</style>